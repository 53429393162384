@use '../variables' as *;

.select-school-form {
    .red-star {
        color: $color-light-red;
      }

      .event-none {
        pointer-events: none;
        cursor: none;
      }

      .member-card {
        border: 1px solid $black-squeeze-color;
        border-radius: 10px;
        padding: 1.2em 1em;
        cursor: pointer;
        box-shadow: none;
        transition: all 0.3 ease;

        &:hover {
          border: 1px solid transparent;
          box-shadow: 0 0 10px $iron-color;
        }

        &.active-card {
          border-color: $shamrock-color;
        }

        img {
          object-fit: cover;
          border-radius: 50px;
        }

        .text-blur {
          color: $aluminium !important;
        }

        .info {
          mat-icon {
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
          }
        }

        .mat-checkbox {
          &.mat-checkbox-checked {
            &.mat-primary {
              .mat-checkbox-frame {
                border-color: $shamrock-color;
              }

              .mat-checkbox-background {
                background-color: $shamrock-color;
                border-radius: 100%;
              }
            }
          }

          .mat-checkbox-background {
            border-radius: 100%;
          }

          .mat-ripple {
            border-radius: 100%;
            scale: 0.8;
          }

          .mat-checkbox-frame {
            border-width: thin;
            border-radius: 100%;
          }

          .mat-checkbox-layout {
            transform: scale(1.3);
          }
        }
      }
}

.container_option {
    display: flex;
    align-items: center;
  
    &.selected {
      padding-left: 40px;
  
      img {
        position: absolute;
        left: 0;
      }
    }
  
    img {
      width: 28px;
      height: 28px;
      object-fit: cover;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      border: 1px solid lightgray;
      margin-right: 8px;
    }
  }
  